import React from "react"
import styles from '../styles/section.module.scss'

const Section = ({ padded = true, children }) => (
  <section className={`${padded === false ? styles.unpadded : 'container'} ${styles.container}`}>
    {children}
  </section>
)

export default Section
