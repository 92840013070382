import React from "react"
import styles from '../styles/event-card.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'

const EventCard = ({ event }) => (
  <div className={styles.container}>
    <div className="columns">
      <div className={`column ${styles.heading}`}>
        <h1 className="title is-1 has-text-dark">{event.childMarkdownRemark.frontmatter.title}</h1>
        <FontAwesomeIcon icon={faBullhorn} className={styles.icon} />
      </div>

      <div className="column has-text-right">
        <h4 className="title is-4 has-text-dark">{event.childMarkdownRemark.frontmatter.date}</h4>

        <h5 className="title is-5 has-text-dark">
          {event.childMarkdownRemark.frontmatter.start_time} to {event.childMarkdownRemark.frontmatter.end_time}
          <br />
          {event.childMarkdownRemark.frontmatter.location}
          <br />
          <a className="has-text-dark" href={event.childMarkdownRemark.frontmatter.address_link}>{event.childMarkdownRemark.frontmatter.address}</a>
        </h5>
      </div>
    </div>

    <div className={styles.banner}></div>
  </div>
)

export default EventCard
