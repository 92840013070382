/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Modals from "./modals"

import "../styles/layout.scss"

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <div className="wrapper">
        <Header toggleModal={this.toggleModal.bind(this)} />
        <main>{this.props.children}</main>
        <Modals isActive={this.state.isOpen} onClose={this.toggleModal.bind(this)} />
      </div>
    )
  }
}
