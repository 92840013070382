import React from "react"
import SubscribeForm from "../components/subscribe-form"

import styles from '../styles/modals.module.scss'

const Modals = ({ isActive, onClose }) => {
  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      
      <div className="modal-card">
        <div className="modal-card-head">
          <p className="modal-card-title">Subscribe</p>
          <button className="delete" onClick={onClose}></button>
        </div>
        <div className="modal-card-body">
          <p>Join our mailing list to get updates on protests and other coordinated actions in our campaign to end ICBC's monopoly.</p>
          <br />
          <SubscribeForm />
        </div>
      </div>
    </div>
  )
}

export default Modals
