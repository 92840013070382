import React from "react"
import styles from '../styles/subscribe-form.module.scss'

import axios from 'axios';
import qs from 'qs';

export default class MyGatsbyComponent extends React.Component {
  constructor(props) {
    super(props)
    
    this._handleSubmit = this._handleSubmit.bind(this)
    this._dismissMessage = this._dismissMessage.bind(this)

    this.state = { email: null, sending: false, sent: false, failureMessage: null }
  }

  _handleSubmit(event) {
    event.preventDefault();

    const email = event.target.email.value;

    if (email.indexOf('@') === -1) {
      window.alert("Please input a proper email address");
      return;
    }

    this.setState({ email, sending: true })

    const url = 'https://libertarian.bc.ca/civicrm/?page=CiviCRM&q=civicrm/profile/create';

    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify({ _qf_default: 'Edit:next', _qf_Edit_next: 'Subscribe', gid: '24', 'email-Primary': email }),
      url
    }

    axios(options).then(response => {
      // nothing - thanks civicrm
    }, (error) => {
      // this.setState({ failureMessage: true })
    }).finally((error) => {
      this.setState({ sent: true })
      this.setState({ sending: false })
    });
  }

  _dismissMessage(event) {
    this.setState({ email: null, sending: false, sent: false, failureMessage: null })
  }

  render() {
    if (this.state.sent) {
      return (
        <div className={styles.container}>
          <div className="message">
            <div className={`message-header ${styles.messageHeader}`}>
              <p>Thanks!</p>
              <button className="delete" onClick={this._dismissMessage}></button>
            </div>

            <div className="message-body">
              We've subscribed <em>{this.state.email}</em> to our email newsletter.
            </div>
          </div>
        </div>
      )
    } else if (this.state.failureMessage) {
      return (
        <div className={styles.container}>
          <div className="message">
            <div className={`message-header ${styles.messageHeader}`}>
              <p>Oops!</p>
              <button className="delete" onClick={this._dismissMessage}></button>
            </div>

            <div className="message-body">
              We couldn't subscribe <em>{this.state.email}</em> to our email newsletter. Try again later?
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.container}>
          <form onSubmit={this._handleSubmit} className={styles.form}>
            <input className={`input is-medium ${styles.input}`} type="email" placeholder="Enter your email" name="email" />

            <button className={`button is-medium ${styles.button} ${this.state.sending ? 'is-loading' : ''}`} type="submit">
              Subscribe
            </button>
          </form>
        </div>
      )
    }
  }
}