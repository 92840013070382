import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'

import Headroom from 'react-headroom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import styles from '../styles/header.module.scss'

class Head extends React.Component {
  constructor(props) {
    super(props)
    
    this._handleModals = this._handleModals.bind(this)
    this.toggleModal = props.toggleModal;
  }

  _handleModals(event) {
    event.preventDefault();

    this.toggleModal();
  }

  render() {
    return (
      <Headroom className={styles.headroomWrapper}>
        <div className={`navbar ${styles.navbar}`}>
          <div className="navbar-brand" className={styles.brand}>
            <a className="navbar-item" href="/">
              End ICBC
            </a>
          </div>

          <div className="navbar-menu is-active">
            <div className="navbar-end">
              <Link to={this.props.event.childMarkdownRemark.frontmatter.path} className={`navbar-item ${styles.link}`}>
                <FontAwesomeIcon icon={faCalendar} className={styles.icon} />
                {this.props.event.childMarkdownRemark.frontmatter.short_title}
              </Link>

              <div className="navbar-item">
                <div className="buttons">
                  <a className={`button is-white ${styles.button}`} onClick={this._handleModals}>
                    Subscribe
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Headroom>
    )
  }
}

const Header = (props) => (
  <StaticQuery
    query={graphql`
      query {
        events: allFile(
          filter: { sourceInstanceName: {eq: "events"}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              id
              childMarkdownRemark {
                frontmatter {
                  path
                  short_title
                  title
                }
              }
            }
          }
        }
      }
    `}
    render={({ events }) => (
      <Head event={events.edges[0].node} {...props} />
    )}
  />
)

export default Header